import axios from "axios";
import { celebApi } from "./api";
import { LoginUserI, RegisterUserI } from "@/interfaces/AuthFormsInterfaces";

export function getImageCode() {
  return axios
    .post(`${celebApi}/user/generateCaptcha`, {})
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function registerUser(user: RegisterUserI) {
  return axios
    .post(`${celebApi}/user/register`, user)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export function loginUser(user: LoginUserI) {
  return axios
    .post(`${celebApi}/user/login`, user)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export async function getUser(token: string) {
  return axios
    .post(
      `${celebApi}/user/getMe`,
      {},
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}
export async function fetchUserProfile(userId: string) {
  return axios
    .get(`${celebApi}/user/getProfile/${userId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export async function verifyUserAccount(token: string) {
  return axios
    .get(`${celebApi}/user/verifyUser/${token}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export function resetPassword(newPassword: string, confirmationCode: string) {
  return axios
    .post(`${celebApi}/user/resetPassword/${confirmationCode}/`, {
      password: newPassword,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export function forgotPassword(email: string) {
  return axios
    .post(`${celebApi}/user/forgotPassword/`, { email })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}
export async function getTotalReports(userId: string): Promise<number> {
  return axios
    .get(`${celebApi}/user/totalReports/${userId}`)
    .then((res) => {
      return res.data.reportCount;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export async function resendEmail(email: string) {
  return axios
    .post(`${celebApi}/user/resendEmail`, { email })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}
